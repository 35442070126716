import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import './MapPage.css';
import { BiWorld } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import Header from './Header';
import Footer from './Footer';

const containerStyle = {
  width: '100%',
  height: '80vh'
};

const defaultCenter = {
  lat: -23.5505,
  lng: -46.6333
};

function MapPage() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDth9SSjz7oFVvyMJ_poWy9-_LNc1eUxD0",
    libraries: ['places', 'geometry']
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [sortBy, setSortBy] = useState('distance');
  const [radius, setRadius] = useState(1000);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          console.log("User location:", userLocation);
          setCenter(userLocation);
        },
        (error) => {
          console.error("Error getting location", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isLoaded && center && map) {
      const service = new window.google.maps.places.PlacesService(map);
      const request = {
        location: center,
        radius: radius.toString(),
        type: 'beauty_salon',
        fields: ['name', 'geometry', 'place_id', 'vicinity', 'website', 'formatted_phone_number', 'rating', 'user_ratings_total']
      };

      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const updatedResults = results.map(place => {
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
              new window.google.maps.LatLng(center.lat, center.lng),
              place.geometry.location
            );
            place.distance = distance < 1000 ? `${distance.toFixed(0)} metros` : `${(distance / 1000).toFixed(2)} km`;
            return place;
          });

          const fetchDetailsPromises = updatedResults.map(place => {
            return new Promise((resolve) => {
              service.getDetails(
                { placeId: place.place_id, fields: ['website', 'formatted_phone_number', 'rating', 'user_ratings_total'] },
                (details, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    place.website = details.website;
                    place.formatted_phone_number = details.formatted_phone_number;
                    place.rating = details.rating;
                    place.user_ratings_total = details.user_ratings_total;
                  }
                  resolve(place);
                }
              );
            });
          });

          Promise.all(fetchDetailsPromises).then((placesWithDetails) => {
            let sortedResults = placesWithDetails;
            if (sortBy === 'alphabetical') {
              sortedResults = placesWithDetails.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortBy === 'rating') {
              sortedResults = placesWithDetails.sort((a, b) => (b.rating || 0) - (a.rating || 0));
            } else if (sortBy === 'reviews') {
              sortedResults = placesWithDetails.sort((a, b) => (b.user_ratings_total || 0) - (a.user_ratings_total || 0));
            } else if (sortBy === 'distance') {
              sortedResults = placesWithDetails.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
            }
            setPlaces(sortedResults);
          });
        } else {
          console.error('PlacesService was not successful for the following reason:', status);
          setPlaces([]);
        }
      });
    }
  }, [isLoaded, map, center, sortBy, radius]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMarkerClick = useCallback((place) => {
    setSelectedPlace(place);
  }, []);

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleRadiusChange = (event) => {
    setRadius(parseInt(event.target.value));
  };

  const formatPhoneNumberForWhatsApp = (phoneNumber) => {
    if (!phoneNumber) return '';

    const cleanedNumber = phoneNumber.replace(/[\s()-]/g, '');
    const digitsAfterDDD = cleanedNumber.slice(2);

    if (digitsAfterDDD.startsWith('9')) {
      const fullNumber = `55${cleanedNumber}`;
      return (
        <p>
          <a href={`https://wa.me/${fullNumber}`} target="_blank" rel="noopener noreferrer">
            <IoLogoWhatsapp size="30" className="icon-align"/> WhatsApp
          </a>
        </p>
      );
    } else if (digitsAfterDDD.length === 8 || digitsAfterDDD.length === 9) {
      return (
        <p>
          <FaPhone size="20" className="icon-align"/> {phoneNumber}
        </p>
      );
    } else {
      return phoneNumber;
    }
  };

  const renderRatingStars = (rating) => {
    if (!rating) return '';

    const roundedRating = rating.toFixed(1);
    const starCount = Math.round(rating);
    const stars = '★'.repeat(starCount) + '☆'.repeat(5 - starCount);
    return `${roundedRating} ${stars}`;
  };

  const renderSortOptions = () => (
    <div className="radio-group">
      <label>
        <input
          type="radio"
          value="distance"
          checked={sortBy === 'distance'}
          onChange={handleSortChange}
        />
        Distância
      </label>
      <label>
        <input
          type="radio"
          value="alphabetical"
          checked={sortBy === 'alphabetical'}
          onChange={handleSortChange}
        />
        Nome
      </label>
      <label>
        <input
          type="radio"
          value="rating"
          checked={sortBy === 'rating'}
          onChange={handleSortChange}
        />
        Nota
      </label>
      <label>
        <input
          type="radio"
          value="reviews"
          checked={sortBy === 'reviews'}
          onChange={handleSortChange}
        />
        Avaliação
      </label>
    </div>
  );

  const renderRadiusOptions = () => (
    <select value={radius} onChange={handleRadiusChange}>
      <option value={1000}>1 km</option>
      <option value={2000}>2 km</option>
      <option value={3000}>3 km</option>
    </select>
  );

  return isLoaded && center ? (
    <div className="container">
      <Header />
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={{
            ...containerStyle,
            borderRadius: '8px'
          }}
          center={center}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {places.map((place) => (
            <Marker
              key={place.place_id}
              position={{
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              }}
              onClick={() => handleMarkerClick(place)}
            />
          ))}

          {selectedPlace && (
            <InfoWindow
              position={{
                lat: selectedPlace.geometry.location.lat(),
                lng: selectedPlace.geometry.location.lng()
              }}
              onCloseClick={() => setSelectedPlace(null)}
            >
              <div>
                <h2>{selectedPlace.name}</h2>
                {selectedPlace.rating && (
                  <p>{renderRatingStars(selectedPlace.rating)} ({selectedPlace.user_ratings_total} avaliações)</p>
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>

      <div className="controls">
        <div>
          <h3>Encontramos {places.length} salões no raio de {renderRadiusOptions()} de você:</h3>
          <p>Ordenar por: {renderSortOptions()}</p>
        </div>

        <div className="places-list">
          <ul>
            {places.map((place) => (
              <li key={place.place_id}>
                <strong>{place.name.toUpperCase()}</strong>
                {place.rating && (
                  <p> {renderRatingStars(place.rating)} ({place.user_ratings_total} avaliações)</p>
                )}
                <p> {place.vicinity}</p>
                {place.distance && (
                  <p>Distância: {place.distance} <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${place.geometry.location.lat()},${place.geometry.location.lng()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Como chegar</strong>
                  </a></p>
                )}

                {place.website && (
                  <p>
                    <a href={place.website} target="_blank" rel="noopener noreferrer" className="link-with-icon">
                      <BiWorld size="30" className="icon-align"/> Site
                    </a>
                  </p>
                )}
                {place.formatted_phone_number && (
                  <p>{formatPhoneNumberForWhatsApp(place.formatted_phone_number)}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  ) : <></>;
}

export default React.memo(MapPage);
